import { useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { Button, Switch, Spin, message, Checkbox, Card } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AdminNavbar from "../../Components/Admin_components/AdminNavbar";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { PMCURVE_LMS_BE_URL } from "../../api";
import { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";

Quill.register("modules/imageResize", ImageResize);
const ContentPage = () => {
  const { state } = useLocation();
  const topic_id = state.topic_id;
  const topic_name = state.topic_name;
  const chapter_name = state.chapter_name;
  const course_name = state.course_name;
  const chapter_id = state.chapter_id;
  const course_id = state.course_id;
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState([]);
  const navigate = useNavigate();
  const quillRef = useRef(null);
  const [questions, setQuestions] = useState([]);
  const [contents, setContents] = useState([]);
  const [summaries, setSummaries] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [addNewContent, setAddNewContent] = useState(false);
  const [addNewSummary, setAddNewSummary] = useState(false);
  const [addNewQuestion, setAddNewQuestion] = useState(false);
  const [addNewRating, setAddNewRating] = useState(false);
  const [ratingValue, setRatingValue] = useState(5);

  const VideoBlot = Quill.import("blots/block/embed");

  class CustomVideoBlot extends VideoBlot {
    static create(value) {
      const node = super.create();
      node.setAttribute("src", value.url);
      node.setAttribute("frameborder", "0");
      node.setAttribute("allowfullscreen", true);
      node.setAttribute("width", "100%");
      node.setAttribute("height", "315");
      return node;
    }

    static value(node) {
      return { url: node.getAttribute("src") };
    }
  }

  CustomVideoBlot.blotName = "video";
  CustomVideoBlot.tagName = "iframe";
  Quill.register(CustomVideoBlot);

  const CustomVideoModule = {
    toolbar: {
      handlers: {
        video: function (value) {
          if (value) {
            const url = prompt("Enter the video URL:");
            if (url) {
              const range = this.quill.getSelection(true);
              this.quill.insertEmbed(
                range.index,
                "video",
                { url: url },
                Quill.sources.USER
              );
              this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
            }
          }
        },
      },
    },
  };

  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     const ImageResize = require("quill-image-resize").default;
  //     ReactQuill.Quill.register("modules/imageResize", ImageResize);
  //   }
  // }, []);

  useEffect(() => {
    getTopicContents();
  }, []);

  useEffect(() => {
    extractData();
  }, [content]);

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.getModule("toolbar").addHandler("image", () => {
        selectLocalImage(quill);
      });
    }
  }, []);

  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        question: "",
        correctFeedback: "",
        wrongFeedback: "",
        choices: [{ answer: "", is_correct_option: false }],
        submitted: false,
        qid: questions.length + 1,
      },
    ]);
    setAddNewQuestion(true);
  };

  const addContent = () => {
    setContents([
      ...contents,
      {
        content: "",
        requireContinueButton: false,
      },
    ]);
    setAddNewContent(true);
  };

  const addSummary = () => {
    setSummaries([
      ...summaries,
      {
        content: "",
        requireContinueButton: false,
      },
    ]);
    setAddNewSummary(true);
  };

  const addRating = () => {
    setRatings([
      ...ratings,
      {
        content: "",
        requireContinueButton: false,
      },
    ]);
    setAddNewRating(true);
  };

  const handleSaveRating = async (index) => {
    const block = ratings[index].content;
    const require_continue_button = ratings[index].requireContinueButton;
    const formattedContent = `<div className='rating'>${block}</div>`;
    const postData = {
      topic_id: topic_id,
      owner: "Rating",
      owner_content: {
        content: formattedContent,
        total_stars: ratingValue,
      },
      require_continue_button: require_continue_button,
    };

    const authRequire = {
      headers: { Authorization: localStorage.getItem("Authorization") },
    };

    try {
      const response = await axios.post(
        `https://${PMCURVE_LMS_BE_URL}/api/v1/admins/topic_contents`,
        postData,
        authRequire
      );
      if (response.status === 200) {
        message.success("Ratings  added successfully!");
        getTopicContents();
      }
      setAddNewRating(false);
    } catch (error) {
      console.error("Error saving rating:", error);
    }
  };

  const updateQuestion = (index, field, value) => {
    const newQuestions = [...questions];
    if (field === "question") {
      newQuestions[index].question = value;
    }
    setQuestions(newQuestions);
  };

  const updateChoice = (questionIndex, choiceIndex, field, value) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].choices[choiceIndex][field] = value;
    setQuestions(newQuestions);
  };

  const addChoice = (questionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].choices.push({
      answer: "",
      is_correct_option: false,
    });
    setQuestions(newQuestions);
  };

  const handleQuestionChange = (value, index) => {
    const newQuestions = [...questions];
    newQuestions[index].question = value;
    setQuestions(newQuestions);
  };

  const handleFeedbackChange = (value, index, isCorrectFeedback) => {
    const newQuestions = [...questions];
    if (isCorrectFeedback) {
      newQuestions[index].correctFeedback = value;
    } else {
      newQuestions[index].wrongFeedback = value;
    }

    setQuestions(newQuestions);
  };

  const updateContentBlock = (content, index) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;

    // Find all images and update their attributes and styles
    const images = tempDiv.getElementsByTagName("img");
    Array.from(images).forEach((img) => {
      const style = img.getAttribute("style");
      const parentSpan = img.parentElement;

      if (style) {
        // Extract width and height values
        const widthMatch = style.match(/width:\s*(\d+)px/);
        const heightMatch = style.match(/height:\s*(\d+)px/);

        // Create a new style string that preserves all original styles
        let newStyle = style;

        // Update or add width and height if they exist
        if (widthMatch) {
          img.setAttribute("width", widthMatch[1]);
          if (!newStyle.includes("width:")) {
            newStyle += `width: ${widthMatch[1]}px;`;
          }
        }
        if (heightMatch) {
          img.setAttribute("height", heightMatch[1]);
          if (!newStyle.includes("height:")) {
            newStyle += `height: ${heightMatch[1]}px;`;
          }
        }

        // Apply the updated style
        img.setAttribute("style", newStyle);
      }

      // Preserve image alignment by checking parent element
      if (parentSpan && parentSpan.tagName.toLowerCase() === "span") {
        const spanStyle = parentSpan.getAttribute("style");
        if (spanStyle) {
          // Ensure the span's style is preserved
          parentSpan.setAttribute("style", spanStyle);
        }

        // Preserve alignment classes
        const classes = parentSpan.getAttribute("class");
        if (classes) {
          parentSpan.setAttribute("class", classes);
        }
      }
    });

    // Get the modified content with all preserved attributes
    const updatedContent = tempDiv.innerHTML;

    const updatedBlocks = [...contents];
    updatedBlocks[index].content = updatedContent;
    setContents(updatedBlocks);
  };

  const updateSummaryBlock = (content, index) => {
    const updatedBlocks = [...summaries];
    updatedBlocks[index].content = content;
    setSummaries(updatedBlocks);
  };
  const updateRatingBlock = (content, index) => {
    const updatedBlocks = [...ratings];
    updatedBlocks[index].content = content;
    setRatings(updatedBlocks);
  };

  const toggleContinueForContent = (index) => {
    const updatedBlocks = [...contents];
    updatedBlocks[index].requireContinueButton =
      !updatedBlocks[index].requireContinueButton;
    setContents(updatedBlocks);
  };

  const toggleContinueForRating = (index) => {
    const updatedBlocks = [...ratings];
    updatedBlocks[index].requireContinueButton =
      !updatedBlocks[index].requireContinueButton;
    setRatings(updatedBlocks);
  };

  const toggleContinueForSummary = (index) => {
    const updatedBlocks = [...summaries];
    updatedBlocks[index].requireContinueButton =
      !updatedBlocks[index].requireContinueButton;
    setSummaries(updatedBlocks);
  };

  const submitContentBlock = async (index) => {
    const block = contents[index].content;
    const require_continue_button = contents[index].requireContinueButton;
    const formattedContent = `<div className='content'>${block}</div>`;
    createRichTextContent(formattedContent, require_continue_button, 0);
    setAddNewContent(false);
    getTopicContents();
  };

  const submitSummaryBlock = async (index) => {
    const block = summaries[index].content;
    const require_continue_button = summaries[index].requireContinueButton;
    const formattedContent = `<div className='summary'>${block}</div>`;
    createRichTextContent(formattedContent, require_continue_button, 1);
    setAddNewSummary(false);
    getTopicContents();
  };

  const submitQuestion = async (index) => {
    const question = questions[index];
    setLoading(true);
    try {
      const response = await axios.post(
        `https://${PMCURVE_LMS_BE_URL}/api/v1/admins/topic_contents`,
        {
          topic_id: topic_id, // make sure topic_id is defined
          owner: "ChoiceQuestion",
          owner_content: {
            question: question.question,
            correct_feedback: question.correctFeedback,
            wrong_feedback: question.wrongFeedback,
            choice_answers_attributes: question.choices,
          },
        },
        {
          headers: { Authorization: localStorage.getItem("Authorization") },
        }
      );
      message.success("Question saved successfully!");
      const updatedQuestions = [...questions];
      updatedQuestions[index].submitted = true;
      setQuestions(updatedQuestions);
    } catch (error) {
      console.error("Failed to submit question:", error);
      message.error("Error submitting question.");
    } finally {
      setAddNewQuestion(false);
      getTopicContents();
      setLoading(false);
    }
  };

  const selectLocalImage = (quill) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      if (file) {
        if (/^image\//.test(file.type)) {
          saveToServer(file, quill);
        } else {
          console.warn("You could only upload images.");
        }
      }
    };
  };

  const saveToServer = (file, quill) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("media_file", file);
    const token = localStorage.getItem("Authorization");
    try {
      axios
        .post(
          `https://${PMCURVE_LMS_BE_URL}/api/v1/admins/multi_medias`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: token,
            },
          }
        )
        .then((response) => {
          const url = response.data.url;
          insertToEditor(url, quill);
        })
        .catch((error) => {
          console.error("Failed to upload image: ", error);
        });
    } catch (error) {
      message.error(error);
    } finally {
      setLoading(false);
    }
  };

  const insertToEditor = (url, quill) => {
    const range = quill.getSelection();
    quill.insertEmbed(range.index, "image", url);
  };

  const toolbarOptions = [
    [{ font: [] }],
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ direction: "rtl" }],
    ["link", "image", "video"],
    [{ align: [] }],
    ["clean"],
  ];

  const imageResizeModuleOptions = {
    parchment: Quill.import("parchment"),
    maintainAspectRatio: true,
    modules: ["Resize", "DisplaySize", "Toolbar"],
    handleStyles: {
      backgroundColor: "black",
      border: "none",
      color: "white",
    },
    displayStyles: {
      backgroundColor: "black",
      border: "none",
      color: "white",
    },
  };

  const quillFormats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "header",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "indent",
    "direction",
    "link",
    "image",
    "video",
    "align",
    "width",
    "height",
    "style",
    "class",
  ];

  const getTopicContents = async () => {
    setLoading(true);
    try {
      const url = `https://${PMCURVE_LMS_BE_URL}/api/v1/admins/topic_contents`;
      const token = localStorage.getItem("Authorization");
      const expiry = localStorage.getItem("expiry");
      const now = new Date();
      const istOffset = 5 * 60 + 30;
      const utcOffset = now.getTimezoneOffset();
      const istTime = new Date(
        now.getTime() + (istOffset + utcOffset) * 60 * 1000
      );
      const istTimestamp = Math.floor(istTime.getTime() / 1000);
      const token_expiry = parseInt(expiry);
      if (
        istTimestamp >= token_expiry ||
        token === undefined ||
        token === null
      ) {
        message.error("Session expired!");
        navigate("/");
      }
      const options = {
        params: { topic_id: topic_id },
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(url, options);
      if (response.status === 200) {
        setContent(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const extractData = () => {
    const newQuestions = [];
    const newContent = [];
    const newSummary = [];
    const newRating = [];
    const everyElement = [];
    for (let index = 0; index < content.length; index++) {
      const element = content[index];

      if (
        element?.ownerType === "RichText" &&
        element?.topicContent?.contentType === "text" &&
        element?.topicContent?.content.match("className='content'")
      ) {
        const needContinue = element?.requireContinueButton;
        const content = element?.topicContent?.content;
        const id = element?.id;

        const new_content = {
          content: content,
          requireContinueButton: needContinue,
          id: id,
        };
        newContent.push(new_content);
        setContents(newContent);
        new_content["type"] = "content";
        new_content["arr_index"] = newContent.length - 1;
        everyElement.push(new_content);
      } else if (
        element?.ownerType === "RichText" &&
        element?.topicContent?.contentType === "summary" &&
        element?.topicContent?.content.match("className='summary'")
      ) {
        const needContinue = element?.requireContinueButton;
        const content = element?.topicContent?.content;
        const id = element?.id;

        const new_content = {
          content: content,
          requireContinueButton: needContinue,
          id: id,
        };
        newSummary.push(new_content);
        setSummaries(newSummary);
        new_content["type"] = "summary";
        new_content["arr_index"] = newSummary.length - 1;
        everyElement.push(new_content);
      } else if (element?.ownerType === "ChoiceQuestion") {
        const id = element?.id;

        const question = {
          question: element.topicContent.question,
          correctFeedback: element.topicContent.correctFeedback,
          wrongFeedback: element.topicContent.wrongFeedback,
          id: id,
          choices: element.topicContent.answers.map((answer) => ({
            id: answer.id,
            answer: answer.answer,
            is_correct_option: answer.isCorrectOption,
          })),
          submitted: false, // Assuming once loaded, it's not editable initially
        };
        newQuestions.push(question);
        setQuestions(newQuestions);
        question["type"] = "question";
        question["arr_index"] = newQuestions.length - 1;
        everyElement.push(question);
      } else if (element?.ownerType === "Rating") {
        const needContinue = element?.requireContinueButton;
        const content = element?.topicContent?.content;
        const id = element?.id;

        const new_content = {
          content: content,
          requireContinueButton: needContinue,
          id: id,
        };
        newRating.push(new_content);
        setRatings(newRating);
        new_content["type"] = "Rating";
        new_content["arr_index"] = newRating.length - 1;
        everyElement.push(new_content);
      }
    }
    setAllItems(everyElement);
  };

  const createRichTextContent = async (
    content,
    continue_required,
    content_type
  ) => {
    setLoading(true);
    try {
      const url = `https://${PMCURVE_LMS_BE_URL}/api/v1/admins/topic_contents`;
      const token = localStorage.getItem("Authorization");
      const expiry = localStorage.getItem("expiry");
      const now = new Date();
      const istOffset = 5 * 60 + 30;
      const utcOffset = now.getTimezoneOffset();
      const istTime = new Date(
        now.getTime() + (istOffset + utcOffset) * 60 * 1000
      );
      const istTimestamp = Math.floor(istTime.getTime() / 1000);
      const token_expiry = parseInt(expiry);
      if (
        istTimestamp >= token_expiry ||
        token === undefined ||
        token === null
      ) {
        message.error("Session expired!");
        navigate("/");
      }
      const params = {
        topic_id: topic_id,
        owner: "RichText",
        owner_content: {
          content: content,
          content_type: content_type,
        },
        require_continue_button: continue_required,
      };
      const response = await axios.post(url, params, {
        headers: { Authorization: token },
      });

      if (response.status === 200) {
        message.success("Content added successfully!");
        getTopicContents();
      }
    } catch (error) {
      console.error(error);
      message.error("Something wrong happened!");
    } finally {
      setLoading(false);
    }
  };

  const goToAllTopics = () => {
    navigate("/chapters/topics", {
      state: {
        chapter_id: chapter_id,
        course_id: course_id,
        name: course_name,
        chapter: chapter_name,
      },
    });
  };

  const deleteTopicElements = async (id) => {
    setLoading(true);
    try {
      const url =
        `https://${PMCURVE_LMS_BE_URL}/api/v1/admins/topic_contents/` +
        String(id);
      const token = localStorage.getItem("Authorization");
      const expiry = localStorage.getItem("expiry");
      const now = new Date();
      const istOffset = 5 * 60 + 30;
      const utcOffset = now.getTimezoneOffset();
      const istTime = new Date(
        now.getTime() + (istOffset + utcOffset) * 60 * 1000
      );
      const istTimestamp = Math.floor(istTime.getTime() / 1000);
      const token_expiry = parseInt(expiry);
      if (
        istTimestamp >= token_expiry ||
        token === undefined ||
        token === null
      ) {
        message.error("Session expired!");
        navigate("/");
      }

      const response = await axios.delete(url, {
        headers: { Authorization: token },
      });

      if (response.status === 200) {
        message.success("Content deleted succesfully!");
      }
    } catch (error) {
      console.error(error);
    } finally {
      getTopicContents();
      setLoading(false);
    }
  };

  const updateRichText = async (
    content_type,
    content,
    continue_required,
    id
  ) => {
    setLoading(true);
    try {
      const url =
        `https://${PMCURVE_LMS_BE_URL}/api/v1/admins/topic_contents/` +
        String(id);
      const token = localStorage.getItem("Authorization");
      const expiry = localStorage.getItem("expiry");
      const now = new Date();
      const istOffset = 5 * 60 + 30;
      const utcOffset = now.getTimezoneOffset();
      const istTime = new Date(
        now.getTime() + (istOffset + utcOffset) * 60 * 1000
      );
      const istTimestamp = Math.floor(istTime.getTime() / 1000);
      const token_expiry = parseInt(expiry);
      if (
        istTimestamp >= token_expiry ||
        token === undefined ||
        token === null
      ) {
        message.error("Session expired!");
        navigate("/");
      }
      const params = {
        topic_id: topic_id,
        owner: "RichText",
        owner_content: {
          content: content,
          content_type: content_type,
        },
        require_continue_button: continue_required,
      };
      const response = await axios.put(url, params, {
        headers: { Authorization: token },
      });

      if (response.status === 200) {
        message.success("Content modified succesfully!");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateTheQuestion = async (questionItem, id) => {
    setLoading(true);
    try {
      const url =
        `https://${PMCURVE_LMS_BE_URL}/api/v1/admins/topic_contents/` +
        String(id);
      const token = localStorage.getItem("Authorization");
      const expiry = localStorage.getItem("expiry");
      const now = new Date();
      const istOffset = 5 * 60 + 30;
      const utcOffset = now.getTimezoneOffset();
      const istTime = new Date(
        now.getTime() + (istOffset + utcOffset) * 60 * 1000
      );
      const istTimestamp = Math.floor(istTime.getTime() / 1000);
      const token_expiry = parseInt(expiry);
      if (
        istTimestamp >= token_expiry ||
        token === undefined ||
        token === null
      ) {
        message.error("Session expired!");
        navigate("/");
      }
      // const currentQuestion = questions.find((question) => question?.id === id);
      const params = {
        topic_id: topic_id,
        owner: "ChoiceQuestion",
        owner_content: {
          wrong_feedback: questionItem.wrongFeedback,
          correct_feedback: questionItem.correctFeedback,
          choice_answers_attributes: questionItem.choices,
          question: questionItem.question,
        },

        require_continue_button: questionItem.continue_required,
      };
      const response = await axios.put(url, params, {
        headers: { Authorization: token },
      });

      if (response.status === 200) {
        message.success("Question modified succesfully!");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateTheRating = async (continue_required, id) => {
    setLoading(true);
    try {
      const url =
        `https://${PMCURVE_LMS_BE_URL}/api/v1/admins/topic_contents/` +
        String(id);
      const token = localStorage.getItem("Authorization");
      const expiry = localStorage.getItem("expiry");
      const now = new Date();
      const istOffset = 5 * 60 + 30;
      const utcOffset = now.getTimezoneOffset();
      const istTime = new Date(
        now.getTime() + (istOffset + utcOffset) * 60 * 1000
      );
      const istTimestamp = Math.floor(istTime.getTime() / 1000);
      const token_expiry = parseInt(expiry);
      if (
        istTimestamp >= token_expiry ||
        token === undefined ||
        token === null
      ) {
        message.error("Session expired!");
        navigate("/");
      }
      const currentRating = ratings.find((rating) => rating?.id === id);
      const params = {
        topic_id: topic_id,
        owner: "Rating",
        owner_content: {
          content: currentRating?.content,
        },

        require_continue_button: continue_required,
      };
      const response = await axios.put(url, params, {
        headers: { Authorization: token },
      });

      if (response.status === 200) {
        message.success("Question modified succesfully!");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateContentRichText = async (index, id) => {
    const block = contents[index].content;
    const require_continue_button = contents[index].requireContinueButton;
    const formattedContent = `<div className='content'>${block}</div>`;
    await updateRichText(0, formattedContent, require_continue_button, id);
    getTopicContents();
  };

  const updateContentChoiceQuestion = async (index, id) => {
    const block = questions[index];
    await updateTheQuestion(block, id);
    getTopicContents();
  };

  const updateContentRating = async (index, id) => {
    const block = [...ratings];
    const require_continue_button = block[index].requireContinueButton;
    await updateTheRating(require_continue_button, id);
    getTopicContents();
  };

  const updateSummaryRichText = async (index, id) => {
    const block = summaries[index].content;
    const require_continue_button = summaries[index].requireContinueButton;
    const formattedContent = `<div className='summary'>${block}</div>`;
    await updateRichText(1, formattedContent, require_continue_button, id);
    getTopicContents();
  };

  const deleteChoice = (qIndex, cIndex) => {
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      newQuestions[qIndex] = {
        ...newQuestions[qIndex],
        choices: newQuestions[qIndex].choices.filter(
          (choice, index) => index !== cIndex
        ),
      };
      return newQuestions;
    });
  };

  return (
    <div>
      <Spin spinning={loading}>
        <AdminNavbar />
        <button
          style={{
            padding: "1%",
            borderRadius: "25px",
            margin: "10px",
            zIndex: "999",
            position: "fixed",
            fontSize: "1rem",
            fontWeight: "bolder",
          }}
          onClick={() => goToAllTopics()}
        >
          <ArrowLeftOutlined />
        </button>
        <h2 style={{ textAlign: "center" }}>Course Name : {course_name}</h2>
        <h2 style={{ textAlign: "center" }}>Chapter Name : {chapter_name}</h2>
        <h2 style={{ textAlign: "center" }}>Topic Name : {topic_name}</h2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100vh",
            flexDirection: "column",
          }}
        >
          <div>
            {allItems.map((item, index) => {
              if (item.type === "content") {
                return (
                  <div>
                    <ReactQuill
                      ref={quillRef}
                      value={contents[item.arr_index].content}
                      style={{
                        width: "85vw",
                        marginTop: "10px",
                      }}
                      onChange={(value) =>
                        updateContentBlock(value, item.arr_index)
                      }
                      theme="snow"
                      modules={{
                        toolbar: toolbarOptions,
                        clipboard: {
                          matchVisual: false,
                        },
                        imageResize: imageResizeModuleOptions,
                      }}
                      formats={quillFormats}
                    />
                    <p>Need a continue button?</p>
                    <Switch
                      value={contents[item.arr_index].requireContinueButton}
                      onChange={(value) =>
                        toggleContinueForContent(item.arr_index)
                      }
                    />
                    <div
                      style={{
                        display: "flex",
                        // justifyContent: "flex-start",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        type="primary"
                        style={{
                          fontFamily: "Arial, sans-serif",
                          fontSize: "16px",
                          marginRight: "10px",
                        }}
                        onClick={() =>
                          updateContentRichText(item.arr_index, item.id)
                        }
                      >
                        Update Content
                      </Button>
                      <Button
                        type="primary"
                        style={{
                          fontFamily: "Arial, sans-serif",
                          fontSize: "16px",
                          marginRight: "10px",
                        }}
                        onClick={() => deleteTopicElements(item.id)}
                      >
                        Delete Content
                      </Button>
                    </div>
                  </div>
                );
              } else if (item.type === "summary") {
                return (
                  <div>
                    <ReactQuill
                      ref={quillRef}
                      value={summaries[item.arr_index].content}
                      style={{
                        width: "85vw",
                        marginTop: "10px",
                      }}
                      onChange={(value) =>
                        updateSummaryBlock(value, item.arr_index)
                      }
                      theme="snow"
                      modules={{
                        toolbar: toolbarOptions,
                        imageResize: imageResizeModuleOptions,
                      }}
                      formats={quillFormats}
                    />
                    <p>Need a continue button?</p>
                    <Switch
                      value={summaries[item.arr_index].requireContinueButton}
                      onChange={(value) =>
                        toggleContinueForSummary(item.arr_index)
                      }
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        type="primary"
                        style={{
                          fontFamily: "Arial, sans-serif",
                          fontSize: "16px",
                          marginRight: "10px",
                        }}
                        onClick={() =>
                          updateSummaryRichText(item.arr_index, item.id)
                        }
                      >
                        Update Summary
                      </Button>
                      <Button
                        type="primary"
                        style={{
                          fontFamily: "Arial, sans-serif",
                          fontSize: "16px",
                          marginRight: "10px",
                        }}
                        onClick={() => deleteTopicElements(item.id)}
                      >
                        Delete Summary
                      </Button>
                    </div>
                  </div>
                );
              } else if (item.type === "question") {
                const currentQuestion = questions.find(
                  (question) => question?.id === item?.id
                );
                const currentQuestionIndex = questions.indexOf(currentQuestion);
                return (
                  <Card style={{ margin: "20px", padding: "10px" }} key={index}>
                    <ReactQuill
                      theme="snow"
                      value={item.question}
                      onChange={(value) =>
                        handleQuestionChange(value, item.arr_index)
                      }
                      ref={quillRef}
                      style={{
                        width: "85vw",
                        marginTop: "10px",
                      }}
                      modules={{
                        toolbar: toolbarOptions,
                        imageResize: imageResizeModuleOptions,
                      }}
                      formats={quillFormats}
                    />
                    <ReactQuill
                      theme="snow"
                      value={currentQuestion?.correctFeedback}
                      onChange={(value) =>
                        handleFeedbackChange(value, currentQuestionIndex, true)
                      }
                      ref={quillRef}
                      style={{
                        width: "85vw",
                        marginTop: "10px",
                        border: "1px solid green",
                        minHeight: "100px",
                      }}
                      placeholder="Enter feedback for correct answer"
                      modules={{
                        toolbar: toolbarOptions,
                        imageResize: imageResizeModuleOptions,
                      }}
                      formats={quillFormats}
                    />
                    {/* <TextArea
                      rows={4}
                      style={{ marginTop: "10px", border: "1px solid green" }}
                      value={questions[questions.length - 1].correctFeedback}
                      onChange={(e) =>
                        handleFeedbackChange(
                          e.target.value,
                          item.arr_index,
                          true
                        )
                      }
                      placeholder="Enter feedback for correct answer"
                    /> */}
                    <ReactQuill
                      theme="snow"
                      value={currentQuestion?.wrongFeedback}
                      onChange={(value) =>
                        handleFeedbackChange(value, currentQuestionIndex, false)
                      }
                      ref={quillRef}
                      style={{
                        width: "85vw",
                        marginTop: "10px",
                        border: "1px solid red",
                        minHeight: "100px",
                      }}
                      placeholder="Enter feedback for wrong answer"
                      modules={{
                        toolbar: toolbarOptions,
                        imageResize: imageResizeModuleOptions,
                      }}
                      formats={quillFormats}
                    />
                    {/* <TextArea
                      rows={4}
                      style={{ marginTop: "10px", border: "1px solid red" }}
                      value={questions[questions.length - 1].wrongFeedback}
                      onChange={(e) =>
                        handleFeedbackChange(
                          e.target.value,
                          questions.length - 1,
                          false
                        )
                      }
                      placeholder="Enter feedback for wrong answer"
                    /> */}

                    {item.choices.map((choice, cIndex) => (
                      <div key={cIndex} style={{ marginBottom: "10px" }}>
                        <ReactQuill
                          theme="snow"
                          value={choice.answer}
                          onChange={(value) =>
                            updateChoice(
                              item.arr_index,
                              cIndex,
                              "answer",
                              value
                            )
                          }
                          ref={quillRef}
                          style={{
                            width: "85vw",
                            marginTop: "10px",
                          }}
                          placeholder="Enter you answer here!"
                          modules={{
                            toolbar: toolbarOptions,
                            imageResize: imageResizeModuleOptions,
                          }}
                          formats={quillFormats}
                        />
                        {/* <Input value={choice.answer} onChange={e => updateChoice(index, cIndex, 'answer', e.target.value)} placeholder="Enter choice answer" /> */}
                        {/* <Input value={choice.feedback} onChange={e => updateChoice(item.arr_index, cIndex, 'feedback', e.target.value)} placeholder="Enter feedback for answer" /> */}
                        <Checkbox
                          checked={choice.is_correct_option}
                          onChange={(e) =>
                            updateChoice(
                              item.arr_index,
                              cIndex,
                              "is_correct_option",
                              e.target.checked
                            )
                          }
                        >
                          Correct
                        </Checkbox>
                      </div>
                    ))}
                    {/* <Button type="dashed" style={{marginRight: "10px"}} onClick={() => addChoice(item.arr_index)}>Add Choice</Button> */}
                    {/* <Button type="primary" onClick={() => submitQuestion(item.arr_index)}>Save Question</Button> */}
                    <Button
                      type="primary"
                      style={{
                        fontFamily: "Arial, sans-serif",
                        fontSize: "16px",
                        marginRight: "10px",
                      }}
                      onClick={() =>
                        updateContentChoiceQuestion(item.arr_index, item.id)
                      }
                    >
                      Update Question
                    </Button>
                    <Button
                      type="primary"
                      style={{
                        fontFamily: "Arial, sans-serif",
                        fontSize: "16px",
                        marginRight: "10px",
                      }}
                      onClick={() => deleteTopicElements(item.id)}
                    >
                      Delete Question
                    </Button>
                  </Card>
                );
              } else if (item.type === "Rating") {
                const currentRating = ratings.find(
                  (rating) => rating?.id === item?.id
                );
                const currentRatingIndex = ratings.indexOf(currentRating);
                return (
                  <div>
                    <ReactQuill
                      ref={quillRef}
                      value={ratings[currentRatingIndex].content}
                      style={{
                        width: "85vw",
                        marginTop: "10px",
                      }}
                      onChange={(value) =>
                        updateRatingBlock(value, currentRatingIndex)
                      }
                      theme="snow"
                      modules={{
                        toolbar: toolbarOptions,
                        imageResize: imageResizeModuleOptions,
                      }}
                      formats={quillFormats}
                    />
                    <p>Need a continue button?</p>
                    <Switch
                      value={ratings[item.arr_index].requireContinueButton}
                      onChange={(value) =>
                        toggleContinueForRating(item.arr_index)
                      }
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        type="primary"
                        style={{
                          fontFamily: "Arial, sans-serif",
                          fontSize: "16px",
                          marginRight: "10px",
                        }}
                        onClick={() =>
                          updateContentRating(item.arr_index, item.id)
                        }
                      >
                        Update Rating
                      </Button>
                      <Button
                        type="primary"
                        style={{
                          fontFamily: "Arial, sans-serif",
                          fontSize: "16px",
                          marginRight: "10px",
                        }}
                        onClick={() => deleteTopicElements(item.id)}
                      >
                        Delete Ratings
                      </Button>
                    </div>
                  </div>
                );
              }
            })}
          </div>
          <div>
            <Button
              type="primary"
              onClick={addQuestion}
              style={{ margin: "10px" }}
            >
              Add New Question
            </Button>
            <Button
              type="primary"
              onClick={addContent}
              style={{ margin: "10px" }}
            >
              Add New content
            </Button>
            <Button
              type="primary"
              onClick={addSummary}
              style={{ margin: "10px" }}
            >
              Add New summary
            </Button>
            <Button
              type="primary"
              onClick={addRating}
              style={{ margin: "10px" }}
            >
              Add New Rating
            </Button>
          </div>
          <div>
            {addNewContent && (
              <div>
                <ReactQuill
                  ref={quillRef}
                  value={contents[contents.length - 1].content}
                  style={{
                    width: "85vw",
                    marginTop: "10px",
                  }}
                  onChange={(value) =>
                    updateContentBlock(value, contents.length - 1)
                  }
                  theme="snow"
                  modules={{
                    toolbar: toolbarOptions,
                    clipboard: {
                      matchVisual: false,
                    },
                    imageResize: imageResizeModuleOptions,
                  }}
                  formats={quillFormats}
                />
                <p>Need a continue button?</p>
                <Switch
                  value={contents[contents.length - 1].requireContinueButton}
                  onChange={(value) =>
                    toggleContinueForContent(contents.length - 1)
                  }
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    type="primary"
                    style={{
                      fontFamily: "Arial, sans-serif",
                      fontSize: "16px",
                      alignItems: "flex-end",
                    }}
                    onClick={() => submitContentBlock(contents.length - 1)}
                  >
                    Save Content
                  </Button>
                </div>
              </div>
            )}

            {addNewSummary && (
              <div>
                <ReactQuill
                  ref={quillRef}
                  value={summaries[summaries.length - 1].content}
                  style={{
                    width: "85vw",
                    marginTop: "10px",
                  }}
                  onChange={(value) =>
                    updateSummaryBlock(value, summaries.length - 1)
                  }
                  theme="snow"
                  modules={{
                    toolbar: toolbarOptions,
                    imageResize: imageResizeModuleOptions,
                  }}
                  formats={quillFormats}
                />
                <p>Need a continue button?</p>
                <Switch
                  value={summaries[summaries.length - 1].requireContinueButton}
                  onChange={(value) =>
                    toggleContinueForSummary(summaries.length - 1)
                  }
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    type="primary"
                    style={{
                      fontFamily: "Arial, sans-serif",
                      fontSize: "16px",
                      alignItems: "flex-end",
                    }}
                    onClick={() => submitSummaryBlock(summaries.length - 1)}
                  >
                    Save Summary
                  </Button>
                </div>
              </div>
            )}

            {addNewQuestion && (
              <Card
                style={{ margin: "20px", padding: "10px" }}
                key={questions.length - 1}
              >
                <ReactQuill
                  theme="snow"
                  value={questions[questions?.length - 1]?.question}
                  onChange={(value) =>
                    handleQuestionChange(value, questions?.length - 1)
                  }
                  placeholder="Enter the question"
                  ref={quillRef}
                  style={{
                    width: "85vw",
                    marginTop: "10px",
                  }}
                  modules={{
                    toolbar: toolbarOptions,
                    imageResize: imageResizeModuleOptions,
                  }}
                  formats={quillFormats}
                />
                <ReactQuill
                  theme="snow"
                  value={questions[questions.length - 1].correctFeedback}
                  onChange={(value) =>
                    handleFeedbackChange(value, questions.length - 1, true)
                  }
                  ref={quillRef}
                  style={{
                    width: "85vw",
                    marginTop: "10px",
                    border: "1px solid green",
                    minHeight: "100px",
                  }}
                  placeholder="Enter feedback for correct answer"
                  modules={{
                    toolbar: toolbarOptions,
                    imageResize: imageResizeModuleOptions,
                  }}
                  formats={quillFormats}
                />
                <ReactQuill
                  theme="snow"
                  value={questions[questions.length - 1].wrongFeedback}
                  onChange={(value) =>
                    handleFeedbackChange(value, questions.length - 1, false)
                  }
                  placeholder="Enter feedback for wrong answer"
                  ref={quillRef}
                  style={{
                    width: "85vw",
                    marginTop: "10px",
                    border: "1px solid red",
                    minHeight: "100px",
                  }}
                  modules={{
                    toolbar: toolbarOptions,
                    imageResize: imageResizeModuleOptions,
                  }}
                  formats={quillFormats}
                />
                {/* <TextArea
                  rows={4}
                  style={{ marginTop: "10px" }}
                  value={questions[questions.length - 1].correctFeedback}
                  onChange={(e) =>
                    handleFeedbackChange(
                      e.target.value,
                      questions.length - 1,
                      true
                    )
                  }
                  placeholder="Enter feedback for correct answer"
                /> */}
                {/* <TextArea
                  rows={4}
                  style={{ marginTop: "10px" }}
                  value={questions[questions.length - 1].wrongFeedback}
                  onChange={(e) =>
                    handleFeedbackChange(
                      e.target.value,
                      questions.length - 1,
                      false
                    )
                  }
                  placeholder="Enter feedback for wrong answer"
                /> */}

                {questions[questions.length - 1]?.choices?.map(
                  (choice, cIndex) => (
                    <div key={cIndex} style={{ marginBottom: "10px" }}>
                      <ReactQuill
                        theme="snow"
                        value={choice.answer}
                        onChange={(value) =>
                          updateChoice(
                            questions.length - 1,
                            cIndex,
                            "answer",
                            value
                          )
                        }
                        ref={quillRef}
                        style={{
                          width: "85vw",
                          marginTop: "10px",
                        }}
                        placeholder="Enter you answer here!"
                        modules={{
                          toolbar: toolbarOptions,
                          imageResize: imageResizeModuleOptions,
                        }}
                        formats={quillFormats}
                      />
                      {/* <Input value={choice.answer} onChange={e => updateChoice(index, cIndex, 'answer', e.target.value)} placeholder="Enter choice answer" /> */}
                      <Checkbox
                        checked={choice.is_correct_option}
                        onChange={(e) =>
                          updateChoice(
                            questions.length - 1,
                            cIndex,
                            "is_correct_option",
                            e.target.checked
                          )
                        }
                      >
                        Correct
                      </Checkbox>
                      {questions[questions.length - 1]?.choices?.length > 1 ? (
                        <Button
                          style={{ marginRight: "10px" }}
                          onClick={() => {
                            deleteChoice(questions.length - 1, cIndex);
                          }}
                        >
                          Delete choice
                        </Button>
                      ) : null}
                    </div>
                  )
                )}
                <Button
                  style={{ marginRight: "10px" }}
                  type="dashed"
                  onClick={() => addChoice(questions.length - 1)}
                >
                  Add Choice
                </Button>
                <Button
                  type="primary"
                  disabled={
                    !questions[questions.length - 1]?.choices?.some(
                      (choice) => choice?.is_correct_option
                    )
                  }
                  onClick={() => submitQuestion(questions.length - 1)}
                >
                  Save Question
                </Button>
              </Card>
            )}

            {addNewRating && (
              <div>
                <ReactQuill
                  ref={quillRef}
                  value={ratings[ratings.length - 1].content}
                  style={{
                    width: "85vw",
                    marginTop: "10px",
                  }}
                  onChange={(value) =>
                    updateRatingBlock(value, ratings.length - 1)
                  }
                  theme="snow"
                  modules={{
                    toolbar: toolbarOptions,
                    imageResize: imageResizeModuleOptions,
                  }}
                  formats={quillFormats}
                />
                <p>Need a continue button?</p>
                <Switch
                  value={ratings[ratings.length - 1].requireContinueButton}
                  onChange={(value) =>
                    toggleContinueForRating(ratings.length - 1)
                  }
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    type="primary"
                    style={{
                      fontFamily: "Arial, sans-serif",
                      fontSize: "16px",
                      alignItems: "flex-end",
                    }}
                    onClick={() => handleSaveRating(ratings.length - 1)}
                  >
                    Save Rating
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default ContentPage;
